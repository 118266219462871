import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent {
  @Input() showAlertModal: boolean = false;
  @Input() type: string = '';
  @Input() message: string = '';
  @Input() url: string = '';
  @Output() displayChange = new EventEmitter();
  iconPath: string = '';
  heading: string = '';

  constructor(private route: Router) { }

  getIconPath() {
    let path = '';
    switch (this.type) {
      case 'info':
        path = 'assets/images/Info.svg';
        break;
      case 'failed':
        path = 'assets/images/cancel.svg';
        break;
      case 'success':
        path = 'assets/images/fireworks.svg';
        break;
      case 'congrats':
        path = 'assets/images/congrats.svg';
        break;
    }
    return path;
  }

  getHeading() {
    let heading = '';
    switch (this.type) {
      case 'info':
        heading = 'COMMON.LABEL.INFOALERT';
        break;
      case 'failed':
        heading = 'COMMON.LABEL.FAILED';
        break;
      case 'success':
        heading = 'COMMON.LABEL.SUCCESS';
        break;
      case 'congrats':
        heading = 'COMMON.LABEL.CONGRATS';
        break;
    }
    return heading;
  }

  getBtnText() {
    let btntext = '';
    switch (this.type) {
      case 'info':
        btntext = 'COMMON.LABEL.CANCEL';
        break;
      case 'failed':
      default:
        btntext = 'COMMON.LABEL.CLOSE';
        break;
    }
    return btntext;
  }

  showBtn(id: string) {
    let show = false;
    if (id === 'btn1') {
      switch (this.type) {
        case 'info':
          show = false;
          break;
        case 'failed':
          show = true;
          break;
      }
    } else {
      switch (this.type) {
        case 'info':
          show = true;
          break;
        case 'failed':
          show = false;
          break;
        case 'success':
          show = true;
          break;
        case 'congrats':
          show = true;
          break;
      }
    }
    return show;
  }

  closeAlertModal() {
    this.displayChange.emit(false);
  };

  routeToPage() {
    switch (this.type) {
      case 'info':
        this.closeAlertModal();
        break;
      case 'success':
      case 'congrats':
        this.closeAlertModal();
        if (this.url.length > 0) {
          this.route.navigate([this.url]);
        }
        break;
    }
  }

  ngOnDestroy() {
    this.displayChange.unsubscribe();
  }
}
