import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent {
  @Input() imageFile?: File;
  @Input() type?: number;
  @Input() cropperMinWidth: number = 0;
  @Input() cropperMinHeight: number = 0;
  @Output() croppedImage = new EventEmitter<any>();
  imageChangedEvent: any;
  scale = 1;
  transform: ImageTransform = {};
  maintainAspectRatio: boolean = true;

  ngOnChanges() {
    if (this.imageFile) {
      this.resetImage();
      if (this.type == 1) {
        this.cropperMinWidth = 175;
        this.cropperMinHeight = 145;
        this.maintainAspectRatio = true;
      } else if (this.type == 2) {
        this.cropperMinWidth = 0;
        this.cropperMinHeight = 0;
        this.maintainAspectRatio = false;
      }else if (this.type == 3) {
        this.cropperMinWidth = 0;
        this.cropperMinHeight = 0;
        this.maintainAspectRatio = false;
      }

      this.readFile(this.imageFile);
      // this.resizeBeforeCrop(this.imageFile);
    }
  }

  readFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imageChangedEvent = null; // clear previous selection
      setTimeout(() => {
        this.imageChangedEvent = { target: { files: [file] } };
      });
    };
  }

  imageCropped(event: ImageCroppedEvent) {
    const base64Image = event.base64;
    if (base64Image) {
      const byteString = atob(base64Image.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: 'image/jpeg' });
      const file = new File([blob], this.imageFile!.name, { type: this.imageFile!.type });
      const objResult = { file: file, type: this.type };
      this.croppedImage.emit(objResult);
    }
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  resetImage() {
    this.scale = 1;
    this.transform = {};
  }

  resizeBeforeCrop(file: File) {
    // Resize the image to a smaller size before cropping
    // Example code using canvas to resize the image
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      this.imageChangedEvent = null; // clear previous selection
      // Calculate new dimensions based on maxWidth and maxHeight
      let maxWidth = 600;
      let maxHeight = 400;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      // Draw image onto canvas
      ctx?.drawImage(img, 0, 0, width, height);

      // Convert canvas to Blob
      canvas.toBlob((blob: any) => {
        const resizedFile = new File([blob], file.name, {
          type: 'image/jpeg', // Adjust according to your image type
          lastModified: Date.now()
        });

        // Pass the resized file to the image cropper
        // this.imageChangedEvent = resizedFile;
        this.imageChangedEvent = { target: { files: [resizedFile] } };
      }, 'image/jpeg');
    };

    img.src = URL.createObjectURL(file);
  }
}
