import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION } from 'ngx-ui-loader';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConfigService } from "./service/config.service";
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { MyinterceptorService } from './myinterceptor.service';
import { ClipboardModule } from 'ngx-clipboard';
import { LangService } from "./service/lang.service";
import { SharedWebModule } from './web/shared/shared.web.module';
import { TranslateService, TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "src/environments/environment";

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'rgba(12,80,219,0.98)',
  bgsOpacity: 1,
  bgsPosition: POSITION.centerCenter,
  bgsSize: 40,
  bgsType: SPINNER.ballSpinClockwise,
  fgsSize: 40,
  fgsColor: '#2494d8',
  fgsPosition: POSITION.centerCenter,
  text: 'Loading...',
  hasProgressBar: false, 
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    ClipboardModule,
    // TranslateModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    SharedModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    SharedWebModule,
    
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (translateService: TranslateService) => () => translateService.use('en').toPromise(),
    //   deps: [TranslateService],
    //   multi: true
    // },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslateService, LangService, ConfigService],
      useFactory: appInitializerFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyinterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

export function translateLoaderFactory(httpClient: HttpClient): TranslateLoader {
  let path = '';
  if (window.location.hostname === 'localhost') {
    path = './assets/i18n/';
  } else {
    let webUrl = environment.WEB_URL;
    const hostnameHasWWW = window.location.hostname.startsWith('www.');
    if (hostnameHasWWW && !webUrl.includes('www.')) {
      const urlParts = webUrl.split('//');
      webUrl = urlParts[0] + '//www.' + urlParts[1];
    } else if (!hostnameHasWWW && webUrl.includes('www.')) {
      webUrl = webUrl.replace('www.', '');
    }
    path = webUrl + 'assets/i18n/';
  }
  return new TranslateHttpLoader(httpClient, path, `.json?ts=${new Date().getTime()}`);
}

export function appInitializerFactory(translate: TranslateService, langService: LangService, configService: ConfigService) {
  return () => {
    return Promise.all([
      new Promise<void>((resolve, reject) => {
        // Initialize translation service
        translate.addLangs(['en', 'ar']); // Add supported languages
        translate.setDefaultLang('en'); // Set default language
        langService.setLang('en');
        translate.use('en').subscribe(
          () => resolve(),
          err => reject(err)
        );
      }),
      configService.loadApplicationConfig(), // Load application configuration
      langService.loadLanguage() // Load language
    ]).then(() => { })
      .catch(err => {
        console.error('Initialization error:', err);
      });
  };
}
