import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, catchError, map, of, switchMap } from "rxjs";
import { response } from "express";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  headers: HttpHeaders = new HttpHeaders();

  constructor(public http: HttpClient) {
    this.headers.set('content-type', 'application/json');
  }

  Get(url: string, isText = false, isPdf = false, isPkpass = false, isXls = false): Observable<any> {
    if (isText) {
      let headers = new HttpHeaders();
      headers = headers.set('content-type', 'application/json');
      return this.http.get<any>(url, { headers: headers, observe: 'response', responseType: 'text' as 'json' });
    } else if (isPdf) {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/pdf');
      return this.http.get<any>(url, { headers: headers, observe: 'response' });
    } else if (isPkpass) {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/vnd.apple.pkpass');
      return this.http.get(url, { headers: headers, observe: 'response', responseType: 'blob' }).pipe(
        switchMap((response: HttpResponse<Blob>) => {
          const contentType = response.headers.get('Content-Type');
          if (!contentType || !response.body) {
            return of(null);
          }

          if (contentType.includes('application/json')) {
            return response.body.text().then((text) => {
              const jsonBody = JSON.parse(text);
              return new HttpResponse({
                body: jsonBody,
                headers: response.headers,
                status: response.status,
                statusText: response.statusText,
                url: response.url!
              });
            });
          } else {
            return of(response);
          }
        })
      );
    } else if (isXls) {
      let headers = new HttpHeaders();
      headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      return this.http.get<any>(url, { headers: headers, observe: 'response', responseType: 'blob' as 'json' });

    } else {
      return this.http.get(url, { observe: 'response' });
    }
  }

  Post(url: string, data: any) {
    return this.http.post<any>(url, data, { observe: 'response' });
  }

  Put(url: string, data: any) {
    return this.http.put<any>(url, data);
  }

  Patch(url: string, data: any) {
    return this.http.patch<any>(url, data);
  }

  Delete(url: string, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: data
    };
    return this.http.delete<any>(url, httpOptions);
  }

  DeleteByID(url: string) {
    return this.http.delete<any>(url);
  }
  getContentType(url: string): Observable<{ contentType: string | null, response: HttpResponse<any> | null }> {
    let headers = new HttpHeaders();
    return this.http.get(url, { headers: headers, observe: 'response' }).pipe(
      map(response => ({
        contentType: response.headers.get('Content-Type'),
        response: response
      })),
      catchError(error => {
        console.error('Error fetching headers:', error);
        return of({ contentType: null, response: null });
      })
    );
  }

}
