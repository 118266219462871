import { environment } from 'src/environments/environment';
import { AddressModel } from './address.model';
import { AuthModel } from './auth.model';

export class UserModel extends AuthModel {
  id: string = "";
  email: string = "";
  countryCode: string = "IN";
  phone: string = "";
  customerID: string = "";
  groupType: number = 0;
  defaultLanguage: string = 'en';
  adminID: string = "";

  // personal information
  firstname: string = "";
  lastname: string = "";
  fullname: string = "";
  photo: string = "";

  // account information
  companyName: string = "";
  logo: string = "";
  address?: AddressModel;
  website: string = "";

  licenseType: string = "";
  totalCards: number = 0;
  pendingCards: number = 0;
  activeCards: number = 0;
  inactiveCards: number = 0;
  expiredCards: number = 0;

  userType: number = 0;
  userTypeName: string = '';
  // menus: menus[] = [];

  public setUser(user: any) {
    this.id = user.id;
    this.adminID = user.adminID;
    this.customerID = user.customerID;
    this.groupType = user.groupType;
    this.email = user.email || '';
    this.phone = user.phone || '';
    this.firstname = user.firstname && user.firstname !== 'undefined' ? user.firstname : '';
    this.lastname = user.lastname && user.lastname !== 'undefined' ? user.lastname : '';
    this.fullname = '';
    if (this.firstname && this.lastname) {
      this.fullname = this.firstname + ' ' + this.lastname;
    } else if (this.firstname) {
      this.fullname = this.firstname;
    } else if (this.lastname) {
      this.fullname = this.lastname;
    }
    this.defaultLanguage = (user.defaultLanguage) ? user.defaultLanguage.toLowerCase() : 'en';
    this.companyName = user.companyName || '';
    this.photo = user.photo || environment.USER_DEFAULT_PIC;
    this.logo = user.logo || environment.COMPANY_DEFAULT_PIC;
    this.address = user.address || new AddressModel();
    this.website = user.website || '';

    this.licenseType = user.licenseType || '';
    this.totalCards = user.totalCards || 0;
    this.pendingCards = user.pendingCards || 0;
    this.activeCards = user.activeCards || 0;
    this.inactiveCards = user.inactiveCards || 0;
    this.expiredCards = user.expiredCards || 0;

    this.userType = user.userType;
    this.userTypeName = user.userTypeName;
    // this.menus = user.menus;
  }
}

export class MenuModel {
  menus: menus[] = [];

  public setMenu(menu: any) {
    this.menus = menu;
  }
}

export class menus {
  access: access[] = [];
  id: string = "";
  name: string = '';
  key: string = '';
  submenu: submenu[] = [];
  url: string = '';
}

export class submenu {
  access: access[] = [];
  id: string = "";
  name: string = '';
  key: string = '';
  url: string = '';
}

export class access {
  id: string = "";
  access_name: string = '';
}
