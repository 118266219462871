<!--header-->
<header
  [class]="
    'lg:flex lg:items-center bg-black lg:justify-between px-4 sm:px-6 lg:px-12 ' +
    dir
  "
>
  <div class="mobile_menu home_pg">
  <div class="b-navbar" *ngIf="!bigScreen">
      <div class="b-navbar__toggle" *ngIf="!bigScreen">
        <button (click)="onToggle()" class="b-navbar__button">
          <span class="b-navbar__icon pl-4">
            <img
              src="../../../../assets/images/burger-bar 1.svg"
              width="32"
              alt=""
            />
          </span>
        </button>
        <div class="flex pt-4 pr-2">
          <a
            class="toggleColour text-white no-underline hover:no-underline flex items-center pl-3 lg:pl-0"
            href="#"
            routerLink="/"
          >
            <img
              src="../../../../assets/img_logo.png"
              alt="logo"
              class="float-left mr-2 w-16 lg:w-20 pr-2"
            />
          </a>
        </div>
      </div>
      <nav
        id="header"
        class="w-1/2 z-30 top-0 lg:justify-center lg:px-8 bg-black toggle_menu"
        *ngIf="showToggle"
      >
        <div
          class="w-full container mx-auto flex items-center justify-between mt-0 py-2"
        >
          <div
            class="flex-grow lg:item-center lg:flex mt-2 lg:mt-0 lg:bg-transparent p-4 lg:p-0 z-20"
            id="nav-content"
          >
            <ul
              class="list-reset lg:flex justify-center flex-1 items-center 2xl:text-[22px]"
            >
              <li class="mr-3 2xl:mr-10">
                <a
                  class="inline-block text-white no-underline hover:cursor-pointer font-medium py-2 px-4"
                  routerLinkActive="active"
                  (click)="Home()"
                  >{{ "HEADER.HOME" | translate }}</a
                >
              </li>
              <li class="mr-3 2xl:mr-10">
                <a
                  class="inline-block text-white no-underline hover:cursor-pointer font-medium py-2 px-4"
                  routerLinkActive="active"
                  (click)="Product()"
                  >{{ "HEADER.PRODUCTS" | translate }}</a
                >
              </li>
              <li class="mr-3 2xl:mr-10">
                <a
                  class="inline-block text-white no-underline hover:cursor-pointer font-medium py-2 px-4"
                  routerLinkActive="active"
                  (click)="Features()"
                  >{{ "HEADER.FEATURES" | translate }}</a
                >
              </li>
              <li class="mr-3 2xl:mr-10">
                <a
                  class="inline-block text-white no-underline hover:cursor-pointer font-medium py-2 px-4"
                  routerLinkActive="active"
                  (click)="Demo()"
                  >{{ "HEADER.DEMO" | translate }}</a
                >
              </li>
              <li class="mr-3 2xl:mr-10">
                <a
                  class="inline-block text-white no-underline hover:cursor-pointer font-medium py-2 px-4"
                  routerLinkActive="active"
                  (click)="Contactus()"
                  >{{ "HEADER.CONTACTUS" | translate }}</a
                >
              </li>
            </ul>
          </div>
          <div>
            <ul
              class="flex-grow flex items-center w-auto mt-2 lg:mt-0 lg:bg-transparent lg:p-0 z-20 2xl:text-[22px]"
            >
              <!-- <li class="lg:mr-3" *ngIf="loginButton">
                <a class="py-2 px-6 hover:cursor-pointer" (click)="cart()"
                  ><img
                    src="../../../../assets/icon_cart.svg"
                    alt="cart"
                    class="2xl:w-7"
                /></a>
              </li> -->
              <li class="lg:mr-3">
                <a
                  class="border-white border text-white hover:cursor-pointer rounded-md font-medium lg:py-2 lg:px-5 py-1 px-2"
                  *ngIf="!loginButton"
                  (click)="login()"
                  >{{ "LOGIN.LOGIN" | translate }}</a
                >
              </li>
              <li class="lg:mr-3 text-white" *ngIf="loginButton">
                <a class="hover:cursor-pointer user_top" (click)="dashBoard()">
                  <img src="./../../../../../assets/loggedin.png" /><span
                    class="welcome"
                    ><span class="welcome_txt">{{
                      "MENU.WELCOME" | translate
                    }}</span
                    ><span class="welcome_user">{{ userName }}</span></span
                  >
                </a>
              </li>
              <li class="lg:mr-3">
                <a class="py-2 px-4 hover:cursor-pointer">
                  <div class="relative inline-block text-left">
                    <form [formGroup]="langForm">
                      <p-selectButton
                        class="px-2 py-1"
                        [options]="stateOptions"
                        formControlName="lang"
                        class="h-1"
                        (click)="this.langser.setLang(this.langForm.value.lang)"
                      >
                        <ng-template let-item>
                          <span>{{ item.toUpperCase() }}</span>
                        </ng-template>
                      </p-selectButton>
                    </form>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <nav
      id="header"
      class="w-full z-30 top-0 lg:justify-center px-3 lg:px-5 bg-black toggle_menu"
      *ngIf="bigScreen"
    >
      <div
        class="w-full container mx-auto flex items-center justify-between mt-0 py-2"
      >
        <div class="flex items-center">
          <a
            class="toggleColour text-white no-underline hover:no-underline flex items-center pl-3 lg:pl-0"
            href="#"
            routerLink="/"
          >
            <img
              src="../../../../assets/img_logo.png"
              alt="logo"
              class="float-left mr-2 w-20"
            />
          </a>
        </div>
        <div
          class="flex-grow lg:item-center lg:flex mt-2 lg:mt-0 lg:bg-transparent p-4 lg:p-0 z-20"
          id="nav-content"
        >
          <ul
            class="list-reset inline-flex lg:flex justify-center flex-1 items-center 2xl:text-[22px]"
          >
            <li class="mr-3 2xl:mr-10">
              <a
                class="inline-block text-white no-underline hover:cursor-pointer font-medium py-2 px-4"
                routerLinkActive="active"
                (click)="Home()"
                >{{ "HEADER.HOME" | translate }}</a
              >
            </li>
            <li class="mr-3 2xl:mr-10">
              <a
                class="inline-block text-white no-underline hover:cursor-pointer font-medium py-2 px-4"
                routerLinkActive="active"
                (click)="Product()"
                >{{ "HEADER.PRODUCTS" | translate }}</a
              >
            </li>
            <li class="mr-3 2xl:mr-10">
              <a
                class="inline-block text-white no-underline hover:cursor-pointer font-medium py-2 px-4"
                routerLinkActive="active"
                (click)="Features()"
                >{{ "HEADER.FEATURES" | translate }}</a
              >
            </li>
            <li class="mr-3 2xl:mr-10">
              <a
                class="inline-block text-white no-underline hover:cursor-pointer font-medium py-2 px-4"
                routerLinkActive="active"
                (click)="Demo()"
                >{{ "HEADER.DEMO" | translate }}</a
              >
            </li>
            <li class="mr-3 2xl:mr-10">
              <a
                class="inline-block text-white no-underline hover:cursor-pointer font-medium py-2 px-4"
                routerLinkActive="active"
                (click)="Contactus()"
                >{{ "HEADER.CONTACTUS" | translate }}</a
              >
            </li>
          </ul>
        </div>
        <div>
          <ul
            class="flex-grow flex items-center w-auto mt-2 lg:mt-0 lg:bg-transparent lg:p-0 z-20 2xl:text-[22px]"
          >
            <!-- <li class="lg:mr-3" *ngIf="loginButton">
              <a class="py-2 px-6 hover:cursor-pointer" (click)="cart()"
                ><img
                  src="../../../../assets/icon_cart.svg"
                  alt="cart"
                  class="2xl:w-7"
              /></a>
            </li> -->
            <li class="lg:mr-3">
              <a
                class="border-white border text-white hover:cursor-pointer rounded-md font-medium lg:px-5 py-1 px-2 text-lg font-family"
                *ngIf="!loginButton"
                (click)="login()"
                >{{ "LOGIN.LOGIN" | translate }}</a
              >
            </li>
            <li class="lg:mr-3 text-white" *ngIf="loginButton">
              <a class="hover:cursor-pointer user_top" (click)="dashBoard()">
                <img src="./../../../../../assets/loggedin.png" /><span
                  class="welcome"
                  ><span class="welcome_txt">{{
                    "MENU.WELCOME" | translate
                  }}</span
                  ><span class="welcome_user">{{ userName }}</span></span
                >
              </a>
            </li>
            <li class="lg:mr-3">
              <a class="py-2 px-4 hover:cursor-pointer">
                <div class="relative inline-block text-left">
                  <!-- <div> -->
                  <form [formGroup]="langForm">
                    <p-selectButton
                      class="px-2 py-1"
                      [options]="stateOptions"
                      formControlName="lang"
                      class="h-1"
                      (click)="this.langser.setLang(this.langForm.value.lang)"
                    >
                      <ng-template let-item>
                        <span>{{ item.toUpperCase() }}</span>
                      </ng-template>
                    </p-selectButton>
                  </form>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</header>
